<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Geocercas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Geocercas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12 float-right">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modal-geocerca"
                        v-if="$store.getters.can('admin.geocercaZona.create')"
                        @click="modalData('Crear', null)"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        Tipo
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipo in listasForms.tipos"
                            :key="tipo.numeracion"
                            :value="tipo.numeracion"
                          >
                            {{ tipo.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Tipo De Operación
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipoOp"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipoOp in listasForms.tiposOp"
                            :key="tipoOp.numeracion"
                            :value="tipoOp.numeracion"
                          >
                            {{ tipoOp.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Bloque
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="filtros.bloque"
                          placeholder="Bloques"
                          label="nombre"
                          :options="listasForms.bloques"
                          :filterable="false"
                          @search="buscarBloques"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th class="text-center">Limite De Velocidad</th>
                      <th class="text-center">Dirección</th>
                      <th class="text-center">Latitud</th>
                      <th class="text-center">Longitud</th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(geocerca, index) in geocercasTable.data"
                      :key="index"
                    >
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            geocerca.tipo_geocerca == 1 ? 'bg-navy' : 'bg-info',
                          ]"
                        >
                          {{ geocerca.tipoGeocerca }}
                        </span>
                      </td>
                      <td>{{ geocerca.nombre }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            geocerca.tipo_operacion == 1
                              ? 'bg-secondary'
                              : 'bg-olive',
                          ]"
                        >
                          {{ geocerca.tipoOperacion }}
                        </span>
                      </td>
                      <td>{{ geocerca.bloque.nombre }}</td>
                      <td>{{ geocerca.limite_vel }}</td>
                      <td>{{ geocerca.direccion }}</td>
                      <td>{{ geocerca.latitud }}</td>
                      <td>{{ geocerca.longitud }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            geocerca.estado == 1
                              ? 'badge-success'
                              : 'badge-danger',
                          ]"
                        >
                          {{ geocerca.estadoGeocerca }}
                        </span>
                      </td>
                      <td>
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-geocerca"
                            v-if="$store.getters.can('admin.geocercaZona.edit')"
                            @click="modalData('Editar', geocerca)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can('admin.geocercaZona.delete')
                            "
                            @click="destroy(geocerca.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="geocercasTable.total">
                  <p>
                    Mostrando del <b>{{ geocercasTable.from }}</b> al
                    <b>{{ geocercasTable.to }}</b> de un total:
                    <b>{{ geocercasTable.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="geocercasTable"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>

        <!-- Modal Geocerca -->
        <div class="modal fade" ref="modalBloque" id="modal-geocerca">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                <h4 class="modal-title text-white">
                  {{ modal.title }} Geocerca
                </h4>
                <button
                  type="button"
                  class="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="close-modal"
                  ref="closeModal"
                  @click="getIndex()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body p-0">
                <ul id="myTab" role="tablist" class="nav nav-tabs">
                  <li class="nav-item">
                    <a
                      id="tab-Geocerca"
                      data-toggle="tab"
                      href="#Geocerca"
                      class="nav-link active"
                      @click="cambiaTab('geocerca')"
                      >Geocerca</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      id="tab-LineaNegocio"
                      data-toggle="tab"
                      href="#LineaNegocio"
                      class="nav-link"
                      >Líneas Negocio</a
                    >
                  </li>
                  <li class="nav-item" v-if="form.id != null">
                    <a
                      id="tab-GeocercaHorarios"
                      data-toggle="tab"
                      href="#GeocercaHorarios"
                      class="nav-link"
                      @click="cambiaTab('horarios')"
                      >Horarios</a
                    >
                  </li>
                </ul>
                <div id="myTabContent" class="tab-content">
                  <div id="Geocerca" class="tab-pane fade active show">
                    <div class="card-body">
                      <!-- formulario de datos -->
                      <div class="row">
                        <div class="form-group col-md-12">
                          <label>Buscar Ubicación:</label>
                          <gmap-autocomplete
                            class="form-control form-control-sm"
                            @place_changed="setPlace"
                            :options="{
                              fields: [
                                'geometry',
                                'formatted_address',
                                'address_components',
                              ],
                            }"
                          >
                            >
                            <template v-slot:input="slotProps">
                              <v-text-field
                                outlined
                                prepend-inner-icon="place"
                                placeholder="Location Of Event"
                                ref="input"
                                v-on:listeners="slotProps.listeners"
                                v-on:attrs="slotProps.attrs"
                              >
                              </v-text-field>
                            </template>
                          </gmap-autocomplete>
                        </div>
                        <div class="form-group col-md-6">
                          <label>Nombre:</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.nombre"
                            :class="
                              $v.form.nombre.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-6">
                          <label>Dirección:</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.direccion"
                            :class="
                              $v.form.direccion.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-3">
                          <label>Tipo De Operación:</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.tipo_operacion"
                            :class="
                              $v.form.tipo_operacion.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="tipoOp in listasForms.tiposOp"
                              :key="tipoOp.numeracion"
                              :value="tipoOp.numeracion"
                            >
                              {{ tipoOp.descripcion }}
                            </option>
                          </select>
                        </div>
                        <div
                          class="form-group col-md-3"
                          v-if="form.tipo_operacion == 2"
                        >
                          <label>Limite De Velocidad:</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="form.limite_vel"
                            :class="
                              $v.form.limite_vel.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div
                          class="form-group col-md-4"
                          v-if="form.tipo_operacion == 1"
                        >
                          <label>Bloque:</label>
                          <v-select
                            :class="[
                              $v.form.bloque.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="form.bloque"
                            placeholder="Bloque"
                            label="nombre"
                            :options="listasForms.bloques"
                            :filterable="false"
                            @search="buscarBloques"
                            class="form-control form-control-sm p-0"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-3">
                          <label>Tipo Geocerca:</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.tipo_geocerca"
                            :class="
                              $v.form.tipo_geocerca.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="tipo in listasForms.tipos"
                              :key="tipo.numeracion"
                              :value="tipo.numeracion"
                            >
                              {{ tipo.descripcion }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-2">
                          <label for="estado">Estado</label>
                          <select
                            id="estado"
                            class="form-control form-control-sm"
                            v-model="form.estado"
                            :class="
                              $v.form.estado.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          >
                            <option value="" disabled>Seleccione...</option>
                            <option value="1">Activo</option>
                            <option value="2">Inactivo</option>
                          </select>
                        </div>
                      </div>
                      <!-- Formulario del mapa -->
                      <div class="row">
                        <div
                          class="form-group col-md-12"
                          v-if="form.tipo_geocerca"
                        >
                          <h4>Ubicación Geocerca</h4>
                        </div>
                        <!-- Si es geocerca poligonal -->
                        <section class="col-md-12">
                          <GeocercaMapPoli ref="geoPoli"></GeocercaMapPoli>
                        </section>
                        <!-- Si es geocerca de circunferencia -->
                        <section class="col-md-12">
                          <GeocercaMapCir ref="geoCir"></GeocercaMapCir>
                        </section>
                      </div>
                    </div>
                  </div>
                  <div id="LineaNegocio" class="tab-pane fade">
                    <div class="card-body">
                      <div class="row">
                        <div class="row">
                          <div
                            class="form-group col-md-6"
                            v-for="linea in listasForms.lineas_negocios"
                            :key="linea.id"
                          >
                            <div
                              class="
                                custom-control
                                custom-switch
                                custom-switch-off-secundary
                                custom-switch-on-success
                              "
                            >
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                :id="'linea_' + linea.id"
                                @change="actualizarLinea(linea.id)"
                                v-model="form_lineas_negocio[linea.id]"
                                :checked="linea.estado"
                              />
                              <label
                                class="custom-control-label"
                                :for="'linea_' + linea.id"
                                >{{ linea.nombre }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="GeocercaHorarios" class="tab-pane fade">
                    <div class="card-body">
                      <div class="row">
                        <div class="float-left">
                          <h3 class="card-title">
                            <h6>
                              <strong>
                                <i class="far fa-clock"></i>
                                Restricción Horarios
                              </strong>
                            </h6>
                          </h3>
                        </div>
                        <table
                          id="users"
                          class="table table-bordered table-hover table-sm"
                        >
                          <thead>
                            <th></th>
                            <th
                              v-for="(dias, index) in listasForms.dias_semana"
                              :key="index.id"
                            >
                              <label for="datos_dia">
                                <input
                                  type="checkbox"
                                  :disabled="opcion == 1"
                                  id="datos_dia"
                                  v-model="form1.datos_dia[index]"
                                />
                                <i class="form-icon"></i>
                              </label>
                              {{ dias.descripcion }}
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Inicio</td>
                              <td
                                v-for="(horaI,
                                index) in listasForms.dias_semana"
                                :key="index.id"
                              >
                                <input
                                  type="text"
                                  id="datos_horaI"
                                  class="form-control form-control-sm"
                                  v-model="form1.datos_horaI[index]"
                                  :disabled="
                                    form1.datos_dia[index] == 0 || opcion == 1
                                  "
                                  v-mask="{
                                    mask: '99:99',
                                    hourFormat: '24',
                                  }"
                                  :class="
                                    !form1.datos_horaI[index]
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Fin</td>
                              <td
                                v-for="(dato_horaF,
                                index) in listasForms.dias_semana"
                                :key="index.id"
                              >
                                <input
                                  type="text"
                                  id="datos_horaF"
                                  class="form-control form-control-sm"
                                  v-model="form1.datos_horaF[index]"
                                  :disabled="
                                    form1.datos_dia[index] == 0 || opcion == 1
                                  "
                                  v-mask="{
                                    mask: '99:99',
                                    hourFormat: '24',
                                  }"
                                  :class="
                                    !form1.datos_horaF[index]
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Observación</td>
                              <td
                                v-for="(obs, index) in listasForms.dias_semana"
                                :key="index.id"
                              >
                                <textarea
                                  v-model="form1.datos_obs[index]"
                                  cols="30"
                                  rows="2"
                                  id="datos_obs"
                                  class="form-control form-control-sm"
                                  :disabled="
                                    form1.datos_dia[index] == 0 || opcion == 1
                                  "
                                  :class="
                                    !form1.datos_obs[index]
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                ></textarea>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer justify-content-between">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-show="!$v.form.$invalid && tab == 'geocerca'"
                  @click="save()"
                >
                  Guardar
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  v-show="tab == 'horarios'"
                  @click="saveHoras()"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../../components/Loading";
import axios from "axios";
import vSelect from "vue-select";
import { required, helpers } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import GeocercaMapCir from "./GeocercaMapCir.vue";
import GeocercaMapPoli from "./GeocercaMapPoli.vue";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "GeocercaIndex",
  components: {
    Loading,
    vSelect,
    pagination,
    GeocercaMapCir,
    GeocercaMapPoli,
  },
  data() {
    return {
      cargando: true,
      tab: "geocerca",
      geocercasTable: {},
      mapCenter: {},
      form1: {},
      metodo: "",
      opcion: null,
      listasForms: {
        tipos: {},
        tiposOp: {},
        bloques: [],
        estados: {},
        lineas_negocios: {},
        dias_semana: [],
      },
      filtros: {
        tipo: null,
        tipoOp: null,
        nombre: null,
        bloque: null,
        bloque_id: null,
        estado: null,
      },
      form: {
        id: null,
        nombre: null,
        tipo_geocerca: null,
        tipo_operacion: null,
        bloque: null,
        direccion: null,
        limite_vel: null,
        radio: null,
        latitud: null,
        longitud: null,
        estado: null,
        detalle_geocerca: [],
      },
      form_lineas_negocio: [],
      modal: {
        title: null,
      },
    };
  },
  validations() {
    if (this.form.tipo_operacion == 1) {
      return {
        form: {
          nombre: {
            required,
          },
          tipo_geocerca: {
            required,
          },
          tipo_operacion: {
            required,
          },
          bloque: {
            required,
          },
          direccion: {
            required,
          },
          latitud: {
            required,
          },
          longitud: {
            required,
          },
          estado: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          nombre: {
            required,
          },
          tipo_geocerca: {
            required,
          },
          tipo_operacion: {
            required,
          },
          limite_vel: {
            required,
          },
          direccion: {
            required,
          },
          latitud: {
            required,
          },
          longitud: {
            required,
          },
          estado: {
            required,
          },
        },
      };
    }
  },
  methods: {
    // Función inical del módulo
    getIndex(page = 1) {
      (this.form1 = {
        datos_id: [],
        datos_dia: ["", "", "", "", "", "", ""],
        datos_horaI: ["", "", "", "", "", "", ""],
        datos_horaF: ["", "", "", "", "", "", ""],
        datos_obs: [],
      }),
        (this.cargando = true);
      this.getTipos();
      this.getTiposOperacion();
      this.getEstados();
      this.getLineasNegocio();

      this.filtros.bloque_id = null;
      if (this.filtros.bloque) {
        this.filtros.bloque_id = this.filtros.bloque.id;
      }

      axios
        .get("/api/admin/basico/geocercaZona?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.geocercasTable = response.data.result;
          this.cargando = false;
        });
    },

    // Funciones de carga de los select del módulo
    getTipos() {
      axios.get("/api/lista/29").then((response) => {
        this.listasForms.tipos = response.data;
      });
    },

    getTiposOperacion() {
      axios.get("/api/lista/148").then((response) => {
        this.listasForms.tiposOp = response.data;
      });
    },

    buscarBloques(search) {
      let me = this;
      me.listasForms.bloques = [];
      if (search != "") {
        var url = "api/admin/bloques/listaSearch?bloque=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.bloques = response.data;
          })
          .catch(function(e) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getDiasSemana() {
      await axios.get("/api/lista/90").then((response) => {
        this.listasForms.dias_semana = response.data;
      });
    },

    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocios = response.data;
      });
    },

    // Funciones de acciones del módulo
    modalData(accion, geocerca) {
      this.modal.title = accion;
      let selected = false;
      this.form_lineas_negocio = [];
      // Se valida si existe geocerca
      if (geocerca && accion == "Editar") {
        // se llena los valores de las líneas de negocio de la geocerca
        selected = false;
        this.listasForms.lineas_negocios.forEach((linea_lista) => {
          geocerca.geocercas_lineas_negocio.forEach((linea) => {
            if (linea_lista.id == linea.id) {
              selected = true;
            }
            this.form_lineas_negocio[linea.id] = selected;
          });
        });
        this.form = {
          id: geocerca.id,
          nombre: geocerca.nombre,
          tipo_geocerca: geocerca.tipo_geocerca,
          tipo_operacion: geocerca.tipo_operacion,
          bloque: {
            id: geocerca.bloque_id,
            nombre: geocerca.bloque.nombre,
          },
          direccion: geocerca.direccion,
          latitud: geocerca.latitud,
          longitud: geocerca.longitud,
          estado: geocerca.estado,
          limite_vel: geocerca.limite_vel,
        };
        // Se carga la información del mapa
        if (geocerca.tipo_geocerca == 1) {
          // Se genera los campos para el formulario de los puntos del mapa
          geocerca.geocercas_zonas_detalles.forEach((punto) => {
            punto.lat = parseFloat(punto.latitud);
            punto.lng = parseFloat(punto.longitud);
          });
          this.form.detalle_geocerca = [geocerca.geocercas_zonas_detalles];
          this.form.puntos_iniciales = this.form.detalle_geocerca;
          this.$refs.geoPoli.initPaths();
        } else {
          this.form.radio = geocerca.radio;
          this.$refs.geoCir.initCir();
        }
        this.buildArrays(geocerca);
      } else {
        this.form = {
          id: null,
          nombre: null,
          tipo_geocerca: null,
          tipo_operacion: null,
          bloque: null,
          limite_vel: null,
          direccion: null,
          radio: null,
          latitud: null,
          longitud: null,
          estado: null,
          detalle_geocerca: [],
        };
        this.form_lineas_negocio = [];
        this.buildArrays(geocerca);
      }
    },
    buildArrays(geocerca) {
      if (geocerca.geocercas_zonas_horarios.length > 0) {
        this.metodo = "PUT";
        this.form1.geocerca_id = this.form.id;
        for (let i = 0; i < geocerca.geocercas_zonas_horarios.length; i++) {
          let dia = geocerca.geocercas_zonas_horarios[i].dia_semana - 1;
          this.form1.datos_id[dia] = geocerca.geocercas_zonas_horarios[i].id;
          this.form1.datos_dia[dia] = geocerca.geocercas_zonas_horarios[i]
            .dia_semana
            ? true
            : false;
          this.form1.datos_obs[dia] =
            geocerca.geocercas_zonas_horarios[i].observacion;
          this.form1.datos_horaI[dia] =
            geocerca.geocercas_zonas_horarios[i].horario_ini;
          this.form1.datos_horaF[dia] =
            geocerca.geocercas_zonas_horarios[i].horario_fin;
        }
      } else {
        this.metodo = "POST";
        this.form1.geocerca_id = this.form.id;
      }
    },
    setPlace(place) {
      const longitud = this.getCentro(place.geometry.viewport.Ra);
      const latitud = this.getCentro(place.geometry.viewport.Bb);
      this.mapCenter = {
        longitud,
        latitud,
      };

      this.form.direccion = place.formatted_address;
      switch (this.form.tipo_geocerca) {
        case 1: // poligonal
          this.$refs.geoPoli.initPaths();
          break;

        case 2: // circular
          this.$refs.geoCir.update("googleAddress", this.mapCenter);
          break;
      }
    },

    getCentro(coordenadas) {
      return (coordenadas.g + coordenadas.h) / 2;
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.form.bloque_id = this.form.bloque.id;
        axios
          .post("/api/admin/basico/geocercaZona", this.form)
          .then((response) => {
            this.$refs.closeModal.click();
            this.form
            this.form = {};
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vualeva a intentarlo... - " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    saveHoras() {
      axios({
        method: this.metodo,
        url: "/api/admin/basico/geocercaZona/saveHoras",
        data: this.form1,
      })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Los horarios se han guardado exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    cambiaTab(tab) {
      this.tab = tab;
    },
    async actualizarLinea(linea_id) {
      await axios
        .put("/api/admin/basico/geocercaZona/assingOrRevokeLinea", {
          geocerca: this.form.id,
          linea_id: linea_id,
          data: this.form_lineas_negocio[linea_id],
        })
        .then((response) => {
          this.getIndex();
          this.$swal({
            icon: "success",
            title: response.data.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        });
    },

    destroy(geocerca_id) {
      this.$swal({
        title: "Está seguro de eliminar esta Geocerca?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/admin/basico/geocercaZona/" + geocerca_id)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se eliminó la Geocerca exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },

  mounted() {
    this.getIndex();
    this.getDiasSemana();
  },
};
</script>
<style>
.pac-container {
  z-index: 1051 !important;
}
</style>
