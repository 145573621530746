<template>
  <div v-if="$parent.form.tipo_geocerca == 2">
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="lat">Latitud</label>
          <input
            type="number"
            step="any"
            v-model.number="$parent.form.latitud"
            class="form-control"
            @change="
              update('editForm', {
                latitud: $parent.form.latitud,
                longitud: $parent.form.longitud,
              })
            "
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="lng">Longitud</label>
          <input
            type="number"
            step="any"
            v-model.number="$parent.form.longitud"
            class="form-control"
            @change="
              update('editForm', {
                latitud: $parent.form.latitud,
                longitud: $parent.form.longitud,
              })
            "
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label for="radius">Radio</label>
          <input
            type="number"
            class="form-control"
            v-model="$parent.form.radio"
            @input="editarCirculo('radius', $parent.form.radio)"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <gmap-map
          :center="center"
          :zoom="15"
          style="width: 100%; height: 400px"
          ref="map"
          @center_changed="update('reportedCenter', $event)"
          @bounds_changed="update('bounds', $event)"
        >
          <gmap-marker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :icon="m.icon"
          ></gmap-marker>
          <gmap-circle
            v-if="displayCircle"
            :center="reportedCenter"
            :bounds="circleBounds"
            :radius="parseFloat(radius)"
            :options="{ editable: true }"
            @radius_changed="editarCirculo('radius', $event)"
            @bounds_changed="editarCirculo('bounds', $event)"
          ></gmap-circle>
        </gmap-map>
      </div>
    </div>
    <div class="row">
      <div class="col">
        Lat: {{ reportedCenter.lat }}, Lng: {{ reportedCenter.lng }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeocercaMapCir",

  data() {
    return {
      latitud: null,
      longitud: null,
      markers: [],
      center: {
        lat: 4.651371,
        lng: -74.071509,
      },
      reportedCenter: {
        lat: 4.651371,
        lng: -74.071509,
      },
      places: [],
      edited: null,
      displayCircle: true,
      circleBounds: {},
      mapBounds: {},
      radius: 1000,
    };
  },

  methods: {
    markerGeocerca() {
      if (this.$parent.form.latitud && this.$parent.form.longitud) {
        this.latitud = this.$parent.form.latitud;
        this.longitud = this.$parent.form.longitud;
      } else {
        this.latitud = this.center.lat;
        this.longitud = this.center.lng;
      }

      const marker = {
        lat: parseFloat(this.latitud),
        lng: parseFloat(this.longitud),
      };
      this.center = marker;
    },

    update(field, event) {
      if (field === "reportedCenter") {
        this.reportedCenter = {
          lat: event.lat(),
          lng: event.lng(),
        };
        this.$parent.form.latitud = event.lat();
        this.$parent.form.longitud = event.lng();
      } else if (field === "bounds") {
        this.mapBounds = event;
      } else if (field === "googleAddress") {
        this.reportedCenter = {
          lat: event.latitud,
          lng: event.longitud,
        };
        this.center = {
          lat: event.latitud,
          lng: event.longitud,
        };
        this.$parent.form.latitud = event.latitud;
        this.$parent.form.longitud = event.longitud;
      } else if (field === "editForm") {
        this.reportedCenter = {
          lat: parseFloat(event.latitud),
          lng: parseFloat(event.longitud),
        };
        this.center = {
          lat: parseFloat(event.latitud),
          lng: parseFloat(event.longitud),
        };
        this.$parent.form.latitud = parseFloat(event.latitud);
        this.$parent.form.longitud = parseFloat(event.longitud);
      } else {
        this.$set(this, field, event);
      }
    },

    editarCirculo(prop, value) {
      if (prop === "radius") {
        this.radius = value;
        this.$parent.form.radio = value;
      } else if (prop === "bounds") {
        this.radius = value;
        this.$parent.form.radio = value;
      }
    },

    initCir() {
      if (this.$parent.form.latitud && this.$parent.form.longitud) {
        this.reportedCenter = {
          lat: parseFloat(this.$parent.form.latitud),
          lng: parseFloat(this.$parent.form.longitud),
        };
        this.center = {
          lat: parseFloat(this.$parent.form.latitud),
          lng: parseFloat(this.$parent.form.longitud),
        };
        this.radius = parseFloat(this.$parent.form.radio);
        this.$parent.form.radio = this.radius;
      } else {
        if (this.$parent.mapCenter.latitud && this.$parent.mapCenter.longitud) {
          this.reportedCenter = {
            lat: parseFloat(this.$parent.mapCenter.latitud),
            lng: parseFloat(this.$parent.mapCenter.longitud),
          };
          this.center = {
            lat: parseFloat(this.$parent.mapCenter.latitud),
            lng: parseFloat(this.$parent.mapCenter.longitud),
          };
        }
        this.$parent.form.latitud = this.reportedCenter.lat;
        this.$parent.form.longitud = this.reportedCenter.lng;
        this.$parent.form.radio = this.radius;
      }
    },
  },

  mounted() {
    // this.markerGeocerca();
    this.initCir();
  },
};
</script>
